// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import SystemMessage from "entity/SystemMessage";
import SystemMessageType from "enums/SystemMessageType";

import { RestController, RestRequest } from "./RestController";

export default class SystemController extends RestController {

    /*
     Get system message.
     */

    getMessage(type: SystemMessageType): Promise<SystemMessage> {
        let request = new RestRequest("/api/v1/system/message/" + type + "", "GET", null, undefined);
        return this.request(request, null) as Promise<SystemMessage>;
    }

}
