import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';

import { LaunchDarklyFlags } from '../services/launch-darkly/launch-darkly.types';
import { getFeatureFlagObservable } from '../state';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnDestroy {
  public enableScootHomeV1 = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(private store: Store) {
    getFeatureFlagObservable(store, LaunchDarklyFlags.scoot_home_v1)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enable) => (this.enableScootHomeV1 = enable));
  }
  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
