<div class="error-modal-content">
  <div class="title-wrap">
    <p class="title" tabindex="0">{{ title }}</p>
    <button title="close detail" (click)="close()">
      <mat-icon class="close-icon" aria-hidden="true" data-mat-icon-type="font"> close </mat-icon>
    </button>
  </div>

  <div class="modal-content">
    <span tabindex="0" class="modal-text" *ngIf="!isJson">{{ text }}</span>
    <pre *ngIf="isJson"><code>{{ json | json }}</code></pre>
    <help-link *ngIf="helpLink" [url]="helpLink.url" [text]="helpLink.text"></help-link>
  </div>
  <div class="action-modal-footer">
    <button class="primary-button" (click)="onNoClick()">OK</button>
  </div>
</div>
