<div class="header" data-testid="header-component-div">
  <system-message *ngIf="isUserAuthed"></system-message>
  <mat-toolbar [ngStyle]="getStyles()">
    <div class="header-logo">
      <a href="https://scoot.app/" class="logo-wrapper m-r-14">
        <img src="assets/images/logos/scoot_logo_primary.png" alt="Scoot Logo" />
      </a>
    </div>

    <div class="header-acted-as" *ngIf="userActedAs">
      SIGNED IN AS: {{ user?.name }}
      <button mat-button (click)="signOutActAsUser()">Sign Out</button>
    </div>

    <div class="header-options hide-lt-md">
      <a mat-button *ngIf="isUserAuthed" routerLink="/" id="socialHeaderLink">HOME</a>
      <a mat-button *ngIf="isUserAuthed" href="https://scoot.app/pricing/">PRICING</a>
    </div>
    <a mat-button *ngIf="!isUserAuthed || isGuest" routerLink="/welcome">Login/Sign Up</a>

    <div class="header-menu hide-gt-sm">
      <button *ngIf="isUserAuthed" mat-icon-button [matMenuTriggerFor]="beforeMenu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="navTo(navType.HOME)">Home</button>
      <button mat-menu-item (click)="navTo(navType.PRICING)">Pricing</button>
    </mat-menu>

    <profile-picture
      #menubutton
      nativeCursor
      *ngIf="isUserReady && !isGuest"
      data-testid="profile-picture"
      [matMenuTriggerFor]="menu"
      [size]="40"
      [user]="user"
      [borderWidth]="'3'"
      [header]="true"
      style="float: right; margin-top: -10px; cursor: pointer"
      tabindex="0"
      role="button"
      aria-pressed="false"
      aria-label="Navigation Menu"
      aria-haspopup="true"
      (keyup.enter)="openMenu()"
      (keydown.enter)="$event.stopPropagation()"
    ></profile-picture>
    <mat-menu #menu="matMenu" role="menu" aria-labelledby="menubutton" data-test-id="user-menu">
      <button mat-menu-item role="menuitem" (click)="navTo(navType.SETTINGS)">My Settings</button>
      <button mat-menu-item role="menuitem" (click)="navTo(navType.ADMIN)">Admin</button>
      <button mat-menu-item role="menuitem" (click)="navTo(navType.PRICING)">Pricing & Features</button>
      <button mat-menu-item role="menuitem" (click)="signOut()">Sign Out</button>
    </mat-menu>
  </mat-toolbar>
</div>
