import { Component, Input } from '@angular/core';

@Component({
  selector: 'help-link',
  template: `
    <p class="help-center-link">
      <img src="assets/images/help-icon.svg" alt="help-icon" />
      <a data-testid="help-center-link" [href]="url" target="_blank">{{ text }}</a>
    </p>
  `,
  styles: ['img { margin-right: 6px; }'],
})
export class HelpLinkComponent {
  @Input() public url = '';
  @Input() public text = '';
}
