import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAuth, signOut } from 'firebase/auth';
import { ReplaySubject } from 'rxjs';
import { filter, finalize, takeUntil, take } from 'rxjs/operators';

import { AuthService } from 'app/services/auth.service';
import { ModalErrorComponent } from 'app/shared/modal-error/modal-error.component';
import { Loggable } from 'app/utils/logging/loggable';
import { notNil } from 'app/utils/stream-util';
import ResultEntity from 'entity/ResultEntity';
import User from 'entity/User';
import RoleType from 'enums/RoleType';

import { LaunchDarklyFlags } from '../services/launch-darkly/launch-darkly.types';
import { NavigationMenuService } from '../services/navigation-menu.service';
import { coreActions, coreSelectors, getFeatureFlagObservable } from '../state';
import STRINGS from '../strings';

enum NavPage {
  SETTINGS,
  ADMIN,
  PRICING,
  HOME,
}

const PRICING_URL = 'https://scoot.app/pricing/';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends Loggable implements OnInit {
  public isGuest = false;
  public user?: User;
  public isUserAuthed = false;
  public isUserAdmin = false;
  public isUserReady = false;
  public actAsDisabled = false;
  public results: ResultEntity[] = [];
  public userActedAs = false;
  private readonly pageSize = 20;
  private isNewAdminActive = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild(MatMenuTrigger) private trigger?: MatMenuTrigger;

  public get navType(): typeof NavPage {
    return NavPage;
  }

  public constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private menuNavService: NavigationMenuService,
    private store: Store,
  ) {
    super();
    this.setDisplayName('HeaderComponent');

    this.store
      .select(coreSelectors.getCompany)
      .pipe(filter(notNil), takeUntil(this.destroyed$))
      .subscribe((company) => {
        this.menuNavService.setCompany(company);
      });

    this.store
      .select(coreSelectors.getUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ isGuest, user }) => {
        this.user = user;
        this.isGuest = isGuest;
        this.menuNavService.user = user;
        this.isUserAuthed = !!user;
        if (user) {
          this.isUserReady = true;
          this.userActedAs = !!user.actedAs;
          this.checkAdminStatus();
        }
      });

    getFeatureFlagObservable(store, LaunchDarklyFlags.new_admin_navigation)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isNewAdminActive) => (this.isNewAdminActive = isNewAdminActive));
  }

  public ngOnInit(): void {
    this.subscribeNavService();
  }

  public getStyles(): Record<string, number | string> {
    const white = 'rgb(255,255,255)';
    const red = 'rgb(255,0,0)';
    const styles = {
      'background-color': white,
      'line-height': 0,
    };

    if (this.userActedAs) {
      styles['background-color'] = red;
    }
    return styles;
  }

  private subscribeNavService(): void {
    this.menuNavService.user$
      .pipe(
        takeUntil(this.destroyed$),
        filter((val) => val && val !== this.user),
      )
      .subscribe((val) => {
        this.authService.setUser(val);
      });
  }

  private checkAdminStatus(): void {
    this.authService
      .checkPrivilege(RoleType.COMPANY_ADMIN)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          this.isUserAdmin = true;
        }
      });
  }

  public signOut(): void {
    if (this.authService.isAnonymous()) {
      signOut(getAuth())
        .then(() => {
          this.cleanAuthVariables();
        })
        .catch((e) => {
          this.error('Error signing out user', { e });
        });
    } else {
      this.authService
        .signOutUser()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((_res) => {
          this.cleanAuthVariables();
        });
    }
  }

  private cleanAuthVariables(): void {
    this.router.navigate(['welcome']).catch((err) => this.error('error navigating to welcome', err));
    this.store.dispatch(coreActions.RESET_AUTH());
    this.authService.setUser(undefined);
    this.user = undefined;
    this.menuNavService.user = undefined;
  }

  public async navTo(page: NavPage): Promise<void> {
    switch (page) {
      case NavPage.SETTINGS:
        this.router.navigate(['web/settings']).catch((err) => this.error('error navigating to settings', err));
        break;
      case NavPage.ADMIN: {
        const adminUsersRoute = this.isNewAdminActive ? 'web/admin/users' : 'web/admin/members';
        this.router.navigate([adminUsersRoute]).catch((err) => this.error('error navigating to admin', err));
        break;
      }
      case NavPage.PRICING: {
        window.location.replace(PRICING_URL);
        break;
      }
      case NavPage.HOME: {
        await this.router.navigate(['/']);
        break;
      }
      default:
        break;
    }
  }
  public openMenu(): void {
    this.trigger?.openMenu();
  }

  private handleError(e: unknown): void {
    this.error('Received error', { e });
    this.dialog.open(ModalErrorComponent, {
      data: STRINGS.DEFAULT_ERROR_MESSAGE,
    });
  }

  public signOutActAsUser(): void {
    this.actAsDisabled = true;
    this.authService
      .removeActAsUser()
      .pipe(
        take(1),
        takeUntil(this.destroyed$),
        finalize(() => (this.actAsDisabled = false)),
      )
      .subscribe({
        next: () => window.location?.reload(),
        error: (e) => this.error('Error while removing act as user', { e }),
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
