import { NavItem } from '../constants';

export const NavItems: NavItem[] = [
  { option: 'Scootaverses', route: '/home/scootaverses', isMenu: false },
  {
    option: 'Events',
    route: '/web/events/landing',
    isMenu: false,
  },
  { option: 'Recordings', route: '/web/admin/recordings', isMenu: false },
  { option: 'Reports', route: '/web/admin/account-reports', isMenu: false },
  // keeping the new constant commented until we finish this feature
  // {
  //   option: 'Reports',
  //   route: '',
  //   isMenu: true,
  //   secondaryItems: [
  //     { option: 'Reports', route: '/web/admin/account-reports' },
  //     { option: 'Summaries', route: '/web/admin/account-summaries' },
  //   ],
  // },
  {
    option: 'Admin',
    route: '',
    isMenu: true,
    secondaryItems: [
      { option: 'Users', route: '/web/admin/users' },
      { option: 'Account Profile', route: '/web/admin/edit-company-profile' },
      { option: 'Integrations', route: '/web/admin/keys' },
    ],
  },
];
