import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Observable,
  from,
  of,
  switchMap,
  BehaviorSubject,
  Subject,
  combineLatest,
  map,
  distinctUntilChanged,
  filter,
} from 'rxjs';

import { actions, AuthenticationState, coreActions, coreSelectors } from 'app/core/state';
import { DeepLinkNavService } from 'app/modules/deep-link/deepLinkNav.service';
import { socialAppSelectors } from 'app/modules/social/state/app';
import { LogService } from 'app/services/logging.service';
import { AppState } from 'app/types';
import { notUndefined } from 'app/utils/stream-util';
import Company from 'entity/Company';
import User from 'entity/User';
import RoleType from 'enums/RoleType';
import CompanyController from 'rest/CompanyController';
import ProfileController from 'rest/ProfileController';
import { RestController } from 'rest/RestController';
import UserController from 'rest/UserController';

import { AmplitudeService } from '../core/services/Amplitude/amplitude.service';

import { CognitoService } from './cognito.service';
import { FirebaseService } from './firebase.service';
import { GuestAuthService } from './guest-auth.service';

const userController = new UserController();
const companyController = new CompanyController();
const userProfileController = new ProfileController();

export interface BasicTempUser {
  loginInput: string;
  password: string;
  countryCode?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user?: User;
  private authenticationSubject = new Subject<string | undefined>();
  private company?: Company;
  private userHelper: BasicTempUser = {
    loginInput: '',
    password: '',
    countryCode: '',
  };
  private userSubject = new BehaviorSubject<User | undefined>(undefined);
  private customerAPIEnabled = false;

  public authentication$ = this.authenticationSubject.asObservable();
  public IN_TEAMS = false;
  public user$ = this.userSubject.asObservable();

  public constructor(
    private store: Store<AppState>,
    private amplitudeService: AmplitudeService,
    private firebaseService: FirebaseService,
    private cognitoService: CognitoService,
    private guestAuthService: GuestAuthService,
    private logService: LogService,
    private router: Router,
    private deepNavService: DeepLinkNavService,
    http: HttpClient,
  ) {
    // check to see if user is using customer api
    this.store.select(socialAppSelectors.customerAPIEnabled).subscribe((api) => {
      this.customerAPIEnabled = api;
    });
    // Wait for each authentication source to emit, then determine the current authentication status. This will emit
    // as the overall authentication status changes.
    combineLatest([
      this.firebaseService.auth$.pipe(map((value) => (value ? 'firebase' : undefined))),
      this.cognitoService.auth$.pipe(map((value) => (value ? 'cognito' : undefined))),
      this.guestAuthService.auth$.pipe(map((value) => (value ? 'guest' : undefined))),
    ])
      .pipe(
        map(([firebase, cognito, guest]) => firebase ?? cognito ?? guest),
        distinctUntilChanged(),
        switchMap((authenticated) => this.completeAuth(authenticated)),
      )
      .subscribe((user) => this.userSubject.next(user));

    RestController.setAuthConfiguration(http, this.getAuthToken.bind(this));

    this.store
      .select(coreSelectors.getConfig)
      .pipe(filter(notUndefined))
      .subscribe((config) => {
        this.firebaseService.init(config);
        this.cognitoService.init(config);
        this.guestAuthService.init();
      });
  }

  /**
   *  Start the sign in process.
   *  Called from components:
   *  modal-social-sign-up, company-profile-page, landing
   */
  public async startSignInProcess({
    navigate,
    reload,
    returnURL,
  }: {
    navigate?: boolean;
    reload?: boolean;
    returnURL?: string;
  }) {
    // Handle Login Event for ChurnZero
    // this.churnZeroService.handleLoginEvent();

    // Handle navigation
    if (reload) {
      window.location.reload();
      return;
    }
    if (!reload && returnURL !== undefined && returnURL !== '') {
      await this.deepNavService.navToDeepLink(returnURL, this.router);
      return;
    }

    if (navigate) {
      this.router.navigate(['/home']).catch((error) => {
        this.logService.error(this.logContext, 'Error navigating to home', error);
      });
      return;
    }
  }

  private async getAuthToken(): Promise<string> {
    if (this.cognitoService.authenticated) {
      return await this.cognitoService.getAuthToken().catch((error) => {
        this.logService.error(this.logContext, 'getAuthToken error', error);
        return '';
      });
    }

    if (this.guestAuthService.authenticated) {
      return await this.guestAuthService.getAuthToken().catch((error) => {
        this.logService.error(this.logContext, 'getAuthToken error', error);
        return '';
      });
    }

    if (this.firebaseService.authenticated) {
      return await this.firebaseService.getAuthToken().catch((error) => {
        this.logService.error(this.logContext, 'getAuthToken error', error);
        return '';
      });
    }

    return '';
  }

  public verifyEmail(verificationCode: string): Observable<void> {
    return from(userController.verifyEmail(verificationCode));
  }

  public async getCurrentCompany(): Promise<Company | undefined> {
    if (this.company) {
      return this.company;
    }
    try {
      const companyRes = await companyController.getCurrentCompany();
      this.company = companyRes;
      return this.company;
    } catch (e) {
      this.logService.error(this.logContext, `get current company error: ${e}`);
    }
  }

  public checkPrivilege(role: RoleType): Observable<boolean> {
    return from(userController.checkPrivilege(role)) as Observable<boolean>;
  }

  public isSignedIn(): boolean {
    return !!this.user;
  }

  public signOutUser(): Observable<undefined> {
    if (!!this._user) {
      userController.signOut();
    }
    this.user = undefined;
    this.company = undefined;
    // Stop Churn Zero when user logs out
    // this.churnZeroService.stopAndLogout();
    this.reinitAuth();
    return of(undefined);
  }

  public getUserSignUp(): BasicTempUser {
    const storedTempUser = this.getStoredTempUser();
    if (this.userHelper.loginInput === '' || this.userHelper.password === '') {
      this.userHelper = storedTempUser;
    }
    return this.userHelper;
  }

  public setTempUser(username: string, password: string, number?: string): void {
    this.userHelper.loginInput = username;
    this.userHelper.password = password;
    this.userHelper.countryCode = number;
    this.storeTempUser();
  }

  public clearTempUser(): void {
    this.userHelper.loginInput = '';
    this.userHelper.password = '';
    this.deleteTempUser();
  }

  public setUser(user?: User): void {
    this.user = user;
  }

  public reinitAuth(): void {
    this.firebaseService.reinitAuth();
    this.cognitoService.reinitAuth();
    this.guestAuthService.reinitAuth();
  }

  public isAnonymous(): boolean {
    return this.guestAuthService.authenticated;
  }

  public removeActAsUser(): Observable<User> {
    return from(userController.removeActAsUser());
  }

  private set user(newUser: User | undefined) {
    if (newUser) {
      this.store.dispatch(actions.USER_AUTH({ user: newUser, isGuest: this.isAnonymous() }));
    } else if (this._user) {
      this.logService.info(this.logContext, 'User logged out');
      this.store.dispatch(coreActions.USER_LOG_OUT());
    }
    this._user = newUser;
  }

  private get user(): User | undefined {
    return this._user;
  }

  /**
   * Complete the auth sequence by retrieving the user
   *
   * As a side effect, also retrieve the company and subscription info
   * @param authenticated Whether or not the user is authenticated
   * @returns
   */
  private async completeAuth(source?: string): Promise<User | undefined> {
    if (this.customerAPIEnabled && (source === 'firebase' || source === 'cognito')) {
      return;
    }

    if (!source) {
      this.store.dispatch(
        coreActions.AUTH_COMPLETE({
          status: AuthenticationState.UNAUTHENTICATED,
        }),
      );
      this.logService.info(this.logContext, 'completeAuth: not authenticated');
      return undefined;
    }

    // Clear guest auth if we're authenticating with a real user
    if (source === 'firebase' || source === 'cognito') {
      this.guestAuthService.reinitAuth();
    }

    this.logService.info(this.logContext, 'completeAuth', { source });
    try {
      const [user, company] = await Promise.all([
        userController.getCurrentUserExt(),
        companyController.getCurrentCompany(),
        ,
      ]);

      const userProfile = !this.isAnonymous ? await userProfileController.getUserProfile() : null;

      this.logService.info(this.logContext, 'completeAuth: authenticated', { user, company });

      if (user.userId) {
        this.user = user;

        this.amplitudeService.setUserId(user.userId);
        this.amplitudeService.setUserInfo(this.isAnonymous());
      }

      if (user.license) {
        const license = user.license;
        this.store.dispatch(coreActions.SET_LICENSE({ license }));
      }

      if (company) {
        this.company = company;
        this.store.dispatch(coreActions.SET_COMPANY({ company }));
        this.amplitudeService.setCompanyInfo(company);
      }

      if (userProfile) {
        this.store.dispatch(coreActions.SET_USER_PROFILE({ userProfile }));
      }

      this.store.dispatch(
        coreActions.AUTH_COMPLETE({
          status: AuthenticationState.AUTHENTICATED,
        }),
      );

      return user;
    } catch (e) {
      this.logService.error(this.logContext, 'completeAuth error', e);
      this.store.dispatch(
        coreActions.AUTH_COMPLETE({
          status: AuthenticationState.UNAUTHENTICATED,
        }),
      );
      return undefined;
    }
  }

  private storeTempUser() {
    localStorage.setItem('tempUser', JSON.stringify(this.userHelper));
  }

  private getStoredTempUser(): {
    loginInput: string;
    password: string;
    countryCode: string;
  } {
    const tempUserToParse = localStorage.getItem('tempUser');
    return tempUserToParse ? JSON.parse(tempUserToParse) : { loginInput: '', password: '', countryCode: '' };
  }

  private deleteTempUser() {
    localStorage.setItem('tempUser', '');
  }

  private get logContext() {
    return { class: 'AuthService' };
  }
}
