import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import STRINGS from 'app/core/strings';

interface HelpLinkData {
  url: string;
  text: string;
}

export type ModalErrorData =
  | string
  | {
      title: string;
      text?: string;
      json?: JSON;
      helpLink?: HelpLinkData;
      isJson?: boolean;
    };

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
  public title = 'Oops!';
  public text = STRINGS.DEFAULT_ERROR_MESSAGE;
  public helpLink?: HelpLinkData;
  public isJson?: boolean;
  public json?: JSON;

  public constructor(
    public dialogRef: MatDialogRef<ModalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalErrorData,
  ) {
    if (typeof data === 'string') {
      this.text = data;
    } else {
      this.title = data?.title ?? this.title;
      this.text = data?.text ?? this.text;
      this.helpLink = data?.helpLink;
      this.isJson = data?.isJson;
      this.json = data?.json;
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
