import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';

import { Loggable } from 'app/utils/logging/loggable';

import SystemMessage from '../../../entity/SystemMessage';
import SystemMessageType from '../../../enums/SystemMessageType';
import SystemController from '../../../rest/SystemController';

@Component({
  selector: 'system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
})
export class SystemMessageComponent extends Loggable implements OnInit, OnDestroy {
  public systemMessage?: SystemMessage;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(private store: Store) {
    super();
  }

  public ngOnInit(): void {
    this.setMessage();
  }

  private setMessage() {
    new SystemController()
      .getMessage(SystemMessageType.GENERAL)
      .then((sm) => {
        this.systemMessage = sm;
      })
      .catch((error) => {
        this.error('Error getting system message', error);
      });
  }

  public showBanner(): boolean {
    if (this.systemMessage && this.systemMessage.message && this.systemMessage.title) {
      return true;
    }

    return false;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
