// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

export enum RoleType {
    ROOT,
    ADMIN,
    COMPANY_ADMIN,
    REWARDS_ADMIN,
    TEAM_ADMIN,
    COMPANY_DEFAULT,
    TEAM_DEFAULT,
    DEFAULT

}
export default RoleType
